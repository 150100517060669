import React from 'react'

type FeatureProps = {
  emoji: string
  title: string
  body: React.ReactNode
}

export const Feature: React.FC<FeatureProps> = ({ emoji, title, body }) => (
  <div>
    <div className='text-7xl'>{emoji}</div>
    <h2 className='mt-7 text-2xl text-primary font-bold'>{title}</h2>
    <div className='text-white text-xl mt-5 max-w-md leading-normal'>
      {body}
    </div>
  </div>
)
