export enum TrackEventType {
  EmailSubmitted = 'email_submitted',
  WebsiteContentLoaded = 'website_content_loaded',
  ImageLoaded = 'image_loaded',
  PageScrolled = 'page_scrolled',
}

type TrackEventOptions = {
  name: TrackEventType
  [key: string]: string | number
}

/**
 * Track a single event.
 */
export function trackEvent({ name, ...parameters }: TrackEventOptions) {
  const service = (window as any).pa

  if (service && service.track) service.track({ name, ...parameters })
}

/**
 * Generate a JS script to track the window onload event.
 *
 * This must be embedded in the <head> tag after the analytics
 * service script.
 */
export function generateWindowOnloadScript() {
  return `window.onload = () => pa.track({ name: "${TrackEventType.WebsiteContentLoaded}" })`
}
