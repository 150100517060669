import React from 'react'
import env from 'env-var'

export const config = {
  mailchimpUrl: env
    .get('GATSBY_MAILCHIMP_URL')
    .default(
      'https://efficiam.us1.list-manage.com/subscribe/post?u=52b36d9d29f4166b2413b804e&id=6542c3214d',
    )
    .asString(),

  googleTagManagerId: env
    .get('GATSBY_GOOGLE_TAG_MANAGER_ID')
    .default('GTM-MKVSBZB')
    .asString(),

  microanalyticsId: env
    .get('GATSBY_MICROANALYTICS_ID')
    .default('ZwSg9rf6GA')
    .asString(),
}

export const ConfigContext = React.createContext(config)
