import React from 'react'
import { config, ConfigContext } from './src/misc/config'
import { Body } from './src/components'

import '@fontsource/lato/400.css'
import '@fontsource/lato/700.css'
import '@fontsource/lato/900.css'

import './src/styles/index.css'

export const wrapRootElement = ({ element }) => (
  <ConfigContext.Provider value={config}>
    <Body />

    {element}
  </ConfigContext.Provider>
)
