import React, { createRef, useEffect } from 'react'
import tippy, { roundArrow } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'

type MagicContentProps = {
  children: string
}

export const MagicContent = ({ children }: MagicContentProps) => {
  const containerRef = createRef<HTMLDivElement>()

  /**
   * Implemented tooltips using a `data-tooltip`
   * attribute.
   */
  useEffect(() => {
    if (!containerRef.current) return

    const tooltipAttribute = 'data-tooltip'

    const tooltips = containerRef.current.querySelectorAll(
      `[${tooltipAttribute}]`,
    )

    tooltips.forEach((tooltip) => {
      const content = tooltip.attributes.getNamedItem(tooltipAttribute)?.value

      tippy(tooltip, {
        content,
        arrow: roundArrow,
      })
    })
  }, [])

  return (
    <div ref={containerRef} dangerouslySetInnerHTML={{ __html: children }} />
  )
}
