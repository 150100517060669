import React from 'react'

type OrderedListProps = {
  items: Array<React.ReactNode>
}

export const OrderedList: React.FC<OrderedListProps> = ({ items }) => (
  <ol className='list-decimal max-w-xl'>
    {items.map((item, index) => (
      <li
        key={index}
        className={`text-white text-xl md:text-2xl leading-normal ${
          index > 0 ? 'mt-6' : ''
        }`}
      >
        {item}
      </li>
    ))}
  </ol>
)
