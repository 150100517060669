import React from 'react'

export const Logo: React.FC = () => (
  <span className='text-4xl font-black tracking-widest'>
    BA<span className='text-primary'>G</span>G
  </span>
)

export const LogoFooter: React.FC = () => (
  <span className='text-2xl font-black tracking-widest opacity-30'>BAGG</span>
)
