import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

type AppMockupProps = {
  alt: string
  onLoad?: () => void
}

export const FirstAppMockup: React.FC<AppMockupProps> = ({ alt, onLoad }) => (
  <StaticImage
    src='../assets/images/app-mockup-1.png'
    alt={alt}
    quality={100}
    placeholder='none'
    onLoad={onLoad}
  />
)

export const SecondAppMockup: React.FC<AppMockupProps> = ({ alt, onLoad }) => (
  <StaticImage
    src='../assets/images/app-mockup-2.png'
    alt={alt}
    quality={100}
    placeholder='none'
    onLoad={onLoad}
  />
)
