import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { ConfigContext } from '../misc/config'
import { generateWindowOnloadScript } from '../misc/tracking'

type HeadProps = {
  title: string
  description: string
  url: string
  image: string
}

export const Head: React.FC<HeadProps> = ({
  title,
  description,
  url,
  image,
}) => {
  const config = useContext(ConfigContext)

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${config.googleTagManagerId}');`}</script>

      <script
        data-host='https://microanalytics.io'
        data-dnt='false'
        src='https://microanalytics.io/js/script.js'
        id={config.microanalyticsId}
        async
        defer
      ></script>

      <script>{generateWindowOnloadScript()}</script>

      <title>{title}</title>

      <link rel='icon' type='image/png' href='/favicon.png' />

      <meta charSet='utf-8' />
      <meta name='title' content={title} />
      <meta name='description' content={description} />

      <meta property='og:type' content='website' />
      <meta property='og:url' content={url} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />

      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:url' content={url} />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image' content={image} />
    </Helmet>
  )
}
