import React, { useContext } from 'react'
import { ConfigContext } from '../misc/config'

export const Body: React.FC = () => {
  const config = useContext(ConfigContext)

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${config.googleTagManagerId}`}
        height='0'
        width='0'
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  )
}
